<template>
  <div>
    <div class="d-flex align-items-center p-3" style="position: relative" v-if="deepGet(preorder, 'config.product_page.enable_filters', false)">
      <select class="form-control select-category mr-2" v-model="query.category" @input="handleCategoryIsChanged">
        <option v-for="(category, i) in formattedCategories" :key="i" :value="category.value">{{ category.label }}</option>
      </select>

      <button class="p-2 btn" @click="isSearching = !isSearching">
        <img src="./images/icon-search.svg" />
      </button>

      <div
        v-if="isSearching"
        class="search px-3 py-2 d-flex align-items-center shadow shadow-sm"
      >
        <form class="search-box p-2 px-3 flex-fill d-flex align-items-center" @submit.prevent="handleSearchIsSubmitted">
          <img src="./images/icon-search.svg" class="icon mr-2" />
          <input type="text" placeholder="搜尋" class="box" autofocus v-model="query.keyword" />
          <input type="submit" style="display: none" />
        </form>
        <button
          class="btn text-black text-nowrap font-weight-bolder"
          @click="handleSearchIsSubmitted"
        >
          送出
        </button>
        <button
          class="btn text-black text-nowrap font-weight-bolder"
          @click="isSearching = !isSearching"
        >
          取消
        </button>
      </div>

      <!-- <button
        class="p-2 btn"
        @click="
          currentView =
            gridViews[(gridViews.indexOf(currentView) + 1) % gridViews.length]
        "
      >
        <img
          v-if="currentView == gridViews[0]"
          src="./images/icon-grid-2.svg"
        />
        <img
          v-if="currentView == gridViews[1]"
          src="./images/icon-grid-1.svg"
        />
        <img
          v-if="currentView == gridViews[2]"
          src="./images/icon-grid-list.svg"
        />
      </button> -->
    </div>

    <div style="flex: 1 1 auto; position: relative">
      <div class="spinner-positioner" v-show="isFetchingPreorder">
        <b-spinner variant="primary"></b-spinner>
      </div>

      <div class="products" :class="currentView">
        <div class="products__item" v-for="sku in formattedSkus" :key="sku.id">
          <div class="products__item__photo">
            <img :src="sku.image" class="img-fluid" v-if="sku.image" />
          </div>

          <div class="products__item__title product__title">{{ sku.title }}</div>

          <div class="products__item__price" v-if="priceIsVisible">$ {{ sku.price }}</div>
          <div class="products__item__price" v-if="pointIsVisible">{{ sku.point }} 點</div>

          <div class="products__item__description" v-html="sku.description"></div>

          <div class="products__item__add-to-cart">
            <b-form-spinbutton
              min="0"
              :value="findSkuQuantityInCart(sku.id)"
              max="5"
              class="text-center px-0"
              :class="{ 'increment-disabled': isPointMode && (totalPoints + sku.point) > memberPoints }"
              style="border-radius: 0.5rem"
              @change="(quantity) => handleSkuQuantityChange(sku.id, quantity)"
            ></b-form-spinbutton>
          </div>
        </div>
      </div>
    </div>

    <Pagination v-model="query.page" :current="query.page" :total-page="lastPage" @input="handlePageIsChanged" class="my-3" />

    <footer class="sticky-bottom footer-action-bar mt-auto">
      <div class="footer-action-bar__summary" :class="{ 'justify-content-center': !priceIsVisible && !pointIsVisible }">
        <div class="footer-action-bar__statistic">
          <img class="footer-action-bar__cart-icon mr-1" src="./images/icon-cart.svg" />
          <span class="footer-action-bar__total-quantity">{{ totalQuantity }}</span>
        </div>
        <div class="footer-action-bar__statistic" v-if="priceIsVisible">
          $
          <span class="footer-action-bar__total-price">
            {{ applyThousandsSeperator(totalPrice) }}
          </span>
        </div>
        <div class="footer-action-bar__statistic" v-if="pointIsVisible">
          <span class="footer-action-bar__total-price mr-2">
            {{ applyThousandsSeperator(totalPoints) }}
          </span>
          點
        </div>
      </div>
      <ShareButton
        @click="$router.push({ name: 'LiffPreorderCart', query: { branch_id: branchId } })"
        class="footer-action-bar__checkout-button"
        :disabled="totalQuantity === 0 || isPointMode && totalPoints > memberPoints"
      >
        下一步
      </ShareButton>
    </footer>
  </div>
</template>

<script>
import ShareButton from "@/components/Page/Liff/Shared/Button";
import Pagination from "@/components/Page/Liff/Shared/PaginationWithoutRedirect";
import themeColor from "@/mixins/liff/themeColor";
import { applyThousandsSeperator } from "@/utils/numberUtils";
import { updateUrlWithoutReload } from "@/utils/updateUrl";
import _ from "lodash";
import deepGet from "lodash/get";
import { mapActions, mapGetters, mapState } from "vuex";

const gridViews = ["grid-list", "grid-2", "grid-1"];

export default {
  components: {
    ShareButton,
    Pagination,
  },
  mixins: [themeColor],
  data() {
    return {
      gridViews,
      currentView: "grid-2",
      isFetchingPreorder: true,
      isSearching: false,
      query: {
        keyword: '',
        category: '',
        page: 1,
      },
      lastPage: 1,
    };
  },
  computed: {
    ...mapGetters('liffPreorder', ['totalQuantity', 'isMoneyMode', 'isPointMode']),
    ...mapState('liffPreorder', { preorder: 'configuration', cart: 'cart', skus: 'skus', categories: 'categories', memberPoints: 'memberPoints' }),
    preorderId() {
      return this.$route.params.preorderId
    },
    branchId() {
      return this.$route.query.branch_id
    },
    formattedSkus() {
      return this.skus.map(skuModel => {
        const titleSegments = [skuModel.product.title]
        if (skuModel.sku_name !== skuModel.product.title) {
          titleSegments.push(skuModel.sku_name)
        }
        return {
          id: skuModel.id,
          title: titleSegments.join(' '),
          description: _.chain(skuModel).get('product.description').defaultTo('').replace(/\n/g, "<br>"),
          price: skuModel.price,
          point: skuModel.point,
          image: deepGet(skuModel, 'product.images[0].pic_url', null),
        }
      })
    },
    priceIsVisible() {
      return this.isMoneyMode
    },
    pointIsVisible() {
      return this.isPointMode
    },
    totalPrice() {
      return Object.keys(this.cart)
        .reduce(
          (total, skuId) => total + this.getSkuPrice(skuId) * this.cart[skuId],
          0,
        )
    },
    totalPoints() {
      return Object.keys(this.cart)
        .reduce(
          (total, skuId) => total + this.getSkuPoint(skuId) * this.cart[skuId],
          0,
        )
    },
    formattedCategories() {
      return [{ label: '全部', value: '' }, ...this.categories.map(category => ({ label: category, value: category }))]
    },
  },
  async mounted() {
    this.debouncedFetchSkus = _.debounce(async () => {
      this.isFetchingPreorder = true
      await this.submitSkuQuery()
      this.isFetchingPreorder = false
    }, 500);

    this.query.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
    this.query.keyword = this.$route.query.keyword
    this.query.category = this.$route.query.category || ''

    try {
      this.isFetchingPreorder = true
      await this.submitSkuQuery()
    } catch (e) {
      this.$swal.fire({ title: "找不到此預選單", type: "error" })
    }
    this.isFetchingPreorder = false
  },
  methods: {
    applyThousandsSeperator,
    deepGet,
    ...mapActions('liffPreorder', ['fetchSkus', 'updateCartQuantity']),
    handleSkuQuantityChange(skuId, quantity) {
      this.updateCartQuantity({ skuId, quantity })
    },
    async handleSearchIsSubmitted() {
      this.query.page = 1

      this.isFetchingPreorder = true
      await this.submitSkuQuery()
      this.isFetchingPreorder = false
    },
    async handleCategoryIsChanged(e) {
      this.query.category = _.get(e, 'target.value')
      this.query.page = 1

      this.isFetchingPreorder = true
      await this.submitSkuQuery()
      this.isFetchingPreorder = false
    },
    async handlePageIsChanged() {
      this.isFetchingPreorder = true
      await this.submitSkuQuery()
      this.isFetchingPreorder = false
    },
    async submitSkuQuery() {
      const response = await this.fetchSkus({
        preorderId: this.preorderId,
        branchId: this.branchId,
        ...this.query,
      })
      updateUrlWithoutReload(this.query, this.$store, this.$route)
      this.query.page = _.get(response, 'data.meta.current_page', 1)
      this.lastPage = _.get(response, 'data.meta.last_page')
    },
    findSkuQuantityInCart(skuId) {
      return deepGet(this.cart, skuId, 0)
    },
    getSkuPrice(skuId) {
      return deepGet(this.skus.find(sku => sku.id === skuId), 'price', 0)
    },
    getSkuPoint(skuId) {
      return deepGet(this.skus.find(sku => sku.id === skuId), 'point', 0)
    },
  },
};
</script>

<style lang="scss" scoped>
.select-category {
  border-radius: 0.5rem;
  border: 0;
  background-color: rgba(236, 239, 241, 0.5);
  font-family: "Noto Sans TC", "Helvetica";
  font-size: 14px;
  font-weight: 500;
}

.search {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;

  .search-box {
    background-color: #eceff180;
    border-radius: 8px;
    .icon {
      width: 14px;
    }
    .box {
      border: none;
      background: none;
      font-size: 14px;
    }
  }
}

.products {
  &__item {
    color: #212121;
    text-decoration: none !important;
    display: grid;
    border: solid 1px #ddd;
    border-radius: 8px;
    padding: 8px;
    background-color: white;

    &__photo {
      grid-area: photo;
      aspect-ratio: 1;

      img {
        width: 100%;
      }
    }

    &__title {
      grid-area: title;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      font-size: 1rem;
      font-weight: 500;
    }

    &__description {
      grid-area: description;
      display: -webkit-box;
      font-size: 0.875rem;
      font-weight: 400;
      color: #aaa;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      grid-area: price;
      margin-bottom: 8px;
      font-size: 1rem;
      font-weight: 300;
    }

    &__add-to-cart {
      grid-area: cart;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      > * + * {
        margin-left: 16px;
      }

      ::v-deep .b-form-spinbutton > output {
        font-weight: 500;
        font-size: 1.1rem;
      }
    }
  }

  &.grid-list {
    padding: 0 4px;

    @media screen and (min-width: 425px) {
      padding: 0 16px;
    }

    .products__item {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content min-content 1fr;
      gap: 0 8px;
      grid-template-areas:
        "photo title"
        "photo price"
        "photo description"
        "photo cart";
      margin-bottom: 4px;

      @media screen and (min-width: 425px) {
        margin-bottom: 16px;
      }

      &__photo {
        height: calc(100% + 16px);
        margin: -8px 0 -8px -8px;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      &__title {
        -webkit-line-clamp: 1;
      }

      &__description {
        -webkit-line-clamp: 1;
      }

      &__add-to-cart {
        margin-top: 16px;
      }
    }
  }

  &.grid-1 {
    grid-template-columns: 1fr;
    display: grid;
    gap: 1rem;
    padding: 0 16px;

    .products__item {
      gap: 0px;
      grid-template-areas:
        "photo"
        "title"
        "price"
        "description"
        "cart";

      &__title {
        text-align: center;
      }

      &__photo {
        margin: -8px -8px 8px;
      }

      &__price {
        text-align: center;
      }

      &__add-to-cart {
        margin-top: 16px;
      }
    }
  }

  &.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    padding: 4px;

    @media screen and (min-width: 512px) {
      gap: 16px;
      padding: 16px;
    }

    .products__item {
      height: 100%;
      grid-template-columns: 100%;
      grid-template-rows: min-content min-content min-content auto;
      gap: 0px;
      grid-template-areas:
        "photo"
        "title"
        "price"
        "description"
        "cart";

      &__title {
        text-align: center;
      }

      &__price {
        text-align: center;
      }

      &__photo {
        margin: -8px -8px 8px;
      }

      &__add-to-cart {
        margin-top: 16px;
      }
    }
  }
}

.filters {
  .filter {
    position: relative;
    background-color: #f5f7f8;
    border-color: #f5f7f8;
    font-size: 14px;
    font-weight: 500;
    &:focus,
    &:active,
    &.active {
      background-color: #90a4ae;
      border-color: #90a4ae;
      color: white;
      &::after {
        content: url("./images/icon-check.svg");
        position: absolute;
        right: 10px;
        fill: black;
      }
    }
  }
}

.footer-action-bar {
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  box-shadow: 0px -2px 4px 0px #0000000D;

  &__summary {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  &__statistic {
    display: inline-flex;
    align-items: center;
    color: #777;
    text-wrap: nowrap;
  }

  &__statistic + &__statistic {
    margin-left: 16px;
  }

  &__total-quantity {
    margin-right: 4px;
    border: 1px solid var(--s-primary);
    border-radius: 50px;
    padding: 0px 6px;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 18px;
    color: var(--s-primary);
  }

  &__cart-icon {
    width: 20px;
  }

  &__total-price {
    margin-left: 4px;
    font-size: 1.25rem;
    font-weight: 500;
    color: black;
  }

  &__checkout-button {
    flex: 0 1 50%;
    margin-left: 16px;
    padding: 12px;
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner-positioner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

::v-deep .increment-disabled [aria-label="Increment"] {
  opacity: 0.3;
  pointer-events: none;
}
</style>
